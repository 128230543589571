const projects = [
  {
    name: "Reflow Oven",
    component: () => import('@/assets/project-content/ReflowOven.md')
  },
  {
    name: "Ergodox",
    component: () => import('@/assets/project-content/Ergodox.md')
  },
  {
    name: "Home Automation",
    component: () => import('@/assets/project-content/HomeAutomation.md')
  },
  {
    name: "Self-hosting",
    component: () => import('@/assets/project-content/Self-hosting.md')
  },
  {
    name: "SOEdge",
    component: () => import('@/assets/project-content/SOEdge.md')
  }
]

let projectRoutes = new Array(projects.length)
for (let i = 0; i < projects.length; i++) {
  projectRoutes[i] = {
    path: projects[i].name.toLowerCase().split(' ').join('_'),
    name: projects[i].name,
    component: projects[i].component
  }
}

export default projectRoutes