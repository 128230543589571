<template>
  <div>
    <span style="float: left;" class="term host">
      juan@website-server
    </span>
    <span style="float: left;" class="term end">
      :
    </span>
    <span style="float: left;" class="term dir">
      ~
    </span>
    <span style="float: left;" class="term end">
      $&nbsp;
    </span>
    <vue-typer style="float: left;"
      :text='[
        "Hello",
        "Welcome to my personal site!",
        "Juan Sanchez | Software Engineer"
      ]'
      :repeat="0"
      :shuffle="false"
      initial-action="typing"
      :pre-type-delay="70"
      :type-delay="60"
      :pre-erase-delay="2000"
      :erase-delay="25"
      erase-style="backspace"
      :erase-on-complete="false"
      caret-animation="blink"
    ></vue-typer>
  </div>
</template>

<script>
import { VueTyper } from "vue-typer";
export default {
  name: "Header",
  props: {
    msg: Array
  },
  components: {
    VueTyper
  }
};
</script>

<style>
.vue-typer {
  font-family: "Ubuntu Mono", "Courier New";
  font-size: 2vw;
}

.vue-typer .custom.char {
  color: rgb(255, 255, 255);
}

.vue-typer .custom.caret {
  width: 1.5vw;
  height: 2.5vw;
  background-color: rgb(255, 255, 255);
}

.term {
  font-family: "Ubuntu Mono", "Courier New";
  font-size: 2vw;
}

.host {
  color: rgb(65, 235, 59);
}

.dir {
  color: rgb(49, 62, 241);
}

.end {
  color: rgb(255, 255, 255);
}
</style>