<template>
  <div class="home">
    <b-container fluid>
      </br>
      <b-row>
        <b-col>
          <b-img class="animated bounceInLeft" fluid rounded thumbnail src="../assets/profile.jpg" alt="Juan Sanchez"/>
        </b-col>
      </b-row>
       <b-row><b-col>&nbsp</b-col></b-row>
      <b-row>
        <b-col cols="2"></b-col>
        <b-col cols="10">
          <Header />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <br/>
          <p class="info intro">
            &nbsp&nbsp&nbsp&nbspAs a Computer Engineer turned Software Engineer, I've been extremely grateful
            for having unique opportunities to be able to dive into the world of Software and Hardware
            in order to become the Engineer I am today. My professional career began in High Performance Computing at ExxonMobil,
            where I quickly learned that just writing software that works <b>isn't enough</b>. Here I began to dive deeper into
            performance optimizations, algorithms, maintainability, portability, and of course testability. After four years
            in this domain, I decided to work on larger scope problems which lead me to joining an internal Innovation team whose
            sole purpose was to solve problems by leveraging the technologies that were out today. Here I began to realize,
            that I love to <b>solve</b> big problems and <b>build</b> the solutions. Getting a chance to experiment with computer
            vision, IoT devices, and integrating them all into cloud solutions in order to deliver an end product.
          <br/><br/>
            &nbsp&nbsp&nbsp&nbspThese experiences then helped me join Amazon for the chance to push myself further
            with new challenges within the world of robotics systems with
            <a href="https://www.aboutamazon.com/news/transportation/meet-scout" target="_blank">Amazon Scout</a>. From working with low level
            protocols to creating and designing AWS infrastructure solutions, bringing a whole new meaning to "full stack". At Scout I got
            the chance to design and implement the "home base" software for the robots. This allowed robots to upload their daily
            mission data through an intricate network in order to deliver the data to applied scientists. After roughly ~2.9PB of
            data uploaded, Amazon Scout came to a close and I was thankfully able to find my next challenge within the AWS BIOS
            firmware team. Here I got an inside look and understanding how servers boot the second they power on. Though my time
            at AWS was short, the amount of knowledge gained was invaluable and allowed me to see how people around the world contribute to open source firmware.
          <br/><br/>
            &nbsp&nbsp&nbsp&nbspThough I may not have been specifically looking for it, a unique opportunity came up to enter the world of Space that
            I simply could <b>not</b> pass up. This had always been an interest of mine, though I never actually thought I'd be able to contribute
            to such a project. Currently, I've joined Amazon's <a href="https://www.aboutamazon.com/what-we-do/devices-services/project-kuiper" target="_blank">Project Kuiper</a>
            initiative, more specifically their Guidance, Navigation, and Control software team. This is the team that essentially pilots the spacecraft
            the moment it's dispensed from the second stage rocket. Here, I've continued to be in a unique position where I can work close to the
            hardware and continue writing software at the level I enjoy. Getting the chance to develop the production propulsion firmware and
            contribute to the satellite's flight software will show me what it takes to write critical, realtime, space-ready code.
          <br/><br/>
            &nbsp&nbsp&nbsp&nbspWhen I'm not in the world of satellites and propulsion, I enjoy spending time with my wife and our dog and cat.
            I also enjoy working on home automation, self-hosted services, 3d-printing, and in general just diving in and out of
            different domains out of curiosity. Checkout the Projects section of the page to see more.
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script scoped>
// @ is an alias to /src
import Header from "@/components/Header.vue";

export default {
  name: "home",
  components: {
    Header
  }
};
</script>

<style>
.home {
  background-color: rgb(0, 43, 54);
  position: absolute;
  left: 0;
  width: 100%;
  min-height: 100vh;
}

.intro {
  padding-left: 10%;
  padding-right: 10%;
}
</style>
