<template>
  <div id="app">
    <b-navbar class="justify-content-center mynav" type="dark" variant="dark" toggleable="md">
      <b-navbar-brand class="w-50 mr-auto"></b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse class="w-100" id="nav-collapse" is-nav>
        <b-navbar-nav class="w-100 justify-content-center">
          <b-nav-item v-bind:active="parentTab === 1" v-on:click="parentTab = 1" to="/">
            Home
          </b-nav-item>
          <b-nav-item v-bind:active="parentTab === 2" v-on:click="parentTab = 2" to="/projects/reflow_oven">
            Projects
          </b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto w-100 justify-content-end">
          <b-nav-item title="Copy to Clipboard" @click="doCopy">{{ email }}</b-nav-item>
          <b-nav-item href="https://www.linkedin.com/in/juan-sanchez-dev" target="_blank">
            LinkedIn
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <router-view/>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      email: 'juan@jsanchez.dev',
      parentTab: 1
    }
  },
  methods: {
    makeToast: function(message, variant = null) {
      this.$bvToast.toast(message, {
        toaster: 'b-toaster-top-left',
        noCloseButton: true,
        autoHideDelay: 2000,
        variant: variant
      })
    },
    doCopy: function() {
      var toast = this.makeToast
      this.$copyText(this.email).then(function (e) {
        toast("Email copied to clipboard.")
      }, function (toast, e) {
        toast('Error copying to clipboard', 'danger')
      })
    }
  }
}
</script>

<style>
 * {
  margin: 0;
}

@font-face {
  font-family: "Ubuntu Mono";
  src: url(assets/fonts/UbuntuMono-R.ttf) format("truetype");
}

.info {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  /* "Ubuntu Mono", "Courier New", */
  font-size: 100%;
  color: rgb(255, 255, 255);
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
}

.copy-button {
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor:pointer;
  overflow: hidden;
  outline:none;
}

#app {
  /* font-family: 'Avenir', Helvetica, Arial, sans-serif; */
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.mynav {
  background-color: #333333;
}

.mynav a {
  padding: 5px;
  color: #999999;
}

.mynav a.router-link-exact-active {
  color: #ffffff;
}
</style>
